import React from 'react'
import { Link } from 'theme-ui'
import SVG from 'react-inlinesvg'
import gatsbySVG from '../../assets/Gatsby-Logo.svg'
import polartr from '../../assets/polartr.png'

const styles = {
  link: {
    display: `inline-flex`,
    color: `heading`,
    fontWeight: `medium`,
    ':visited': {
      color: `heading`
    },
    svg: {
      fill: `omegaDarker`,
      height: 24,
      ml: 2
    }
  }
}

const PoweredByPolar = () => (
  <Link
    variant='mute'
    target='_blank'
    title='Polar'
    href='https://polar.io'
    rel='noopener'
    sx={styles.link}
  >
    Powered By{' '}
    <img
      src={polartr}
      width='100px'
      style={{ alignSelf: 'flex-start', marginLeft: '5px' }}
    />
    {/* {polar && <SVG src={gatsbySVG} />} */}
  </Link>
)

export default PoweredByPolar
